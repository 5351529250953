import { useCallback, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
// components
import ProjectPreview from 'src/components/ProjectPreview';
import Pagination from 'src/components/Pagination';
import Footer from 'src/containers/Footer';
import Section from 'src/components/Section';
// hooks
import usePortfolioData from 'src/cms/data/home/usePortfolioData';
import useWindowSize from 'src/hooks/useWindowSize';

import * as style from './Projects.module.scss';

gsap.registerPlugin(ScrollToPlugin);

const Projects = () => {
  // hooks
  const { list } = usePortfolioData();
  const { isMobile, isTablet } = useWindowSize();
  // state
  const [activeItem, setActiveItem] = useState<number | null>(null);
  // ref
  const scrollerRef = useRef<HTMLDivElement>(null);
  const isAnimating = useRef<boolean>(false);

  const slideAnimation = useCallback(() => {
    if (isAnimating.current) {
      return;
    }

    isAnimating.current = true;

    if (scrollerRef.current) {
      gsap.to(scrollerRef.current, {
        ease: 'power3.out',
        duration: 1.5,
        scrollTo: { y: (activeItem || 0) * document.body.clientHeight },
        onStart: () => (isAnimating.current = true),
        onComplete: () => (isAnimating.current = false),
      });
    }
  }, [activeItem]);

  const scrollToActiveItem = useCallback(
    (event: WheelEvent) => {
      if (isAnimating.current) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      const newActiveItem =
        event.deltaY > 0 ? (activeItem || 0) + 1 : (activeItem || 0) - 1;

      if (newActiveItem < 0 || newActiveItem >= list.length) {
        return;
      }

      setActiveItem(newActiveItem);
    },
    [activeItem, setActiveItem]
  );

  useEffect(() => {
    if (activeItem === null) {
      return;
    }

    slideAnimation();
  }, [activeItem, slideAnimation]);

  useEffect(() => {
    if (scrollerRef.current) {
      document.addEventListener('wheel', scrollToActiveItem);
    }

    return () => {
      if (scrollerRef.current) {
        document.removeEventListener('wheel', scrollToActiveItem);
      }
    };
  }, [scrollToActiveItem]);

  const handlePaginationClick = useCallback((index: number) => {
    if (isAnimating.current) {
      return;
    }

    setActiveItem(index);
  }, []);

  return (
    <>
      <Section withMargin={false}>
        <div className={style.scrollable} ref={scrollerRef}>
          {list.map((item, index) => (
            <div key={index} className={style.project}>
              <ProjectPreview isAnimation={isMobile || isTablet} {...item} />
            </div>
          ))}
        </div>
      </Section>
      <div className={style.pagination}>
        <Pagination
          variant='numeric'
          itemsCount={list.length}
          activeItem={activeItem || 0}
          onClick={handlePaginationClick}
        />
      </div>
      <div className={style.footer}>
        <Footer />
      </div>
    </>
  );
};

export default Projects;
