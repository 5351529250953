import { FC, useCallback, useContext, useEffect, useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
// components
import Typography from 'src/components/Typography';
import Link from 'src/components/Link/Link';
// context
import CursorContext from 'src/contexts/CursorContext';
// animation
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { showBlock, showFromOpacity } from 'src/animations/block';

import { Props } from './ProjectPreview.interface';
import * as style from './ProjectPreview.module.scss';

const ProjectPreview: FC<Props> = ({
  title,
  description,
  frameColor,
  link,
  image,
  isAnimation,
  isProjectReady,
  id,
  height,
}) => {
  // state
  const { cursor } = useContext(CursorContext);
  // refs
  const textAnimationRefs = useRef<HTMLDivElement[]>([]);
  const borderRef = useRef<HTMLDivElement>(null);
  const phoneRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textAnimationRefs.current.length > 0 && isAnimation) {
      const animation = appearanceOfText(textAnimationRefs.current).pause();

      onHTMLElementEnterOnWindow(textAnimationRefs.current[0], () => {
        animation.play();
      });
    }
  }, [isAnimation]);

  useEffect(() => {
    if (linkRef.current !== null && isAnimation) {
      const animation = showFromOpacity(linkRef.current).pause();

      onHTMLElementEnterOnWindow(linkRef.current, () => {
        animation.play();
      });
    }
  }, [isAnimation]);

  useEffect(() => {
    if (
      borderRef.current !== null &&
      phoneRef.current !== null &&
      isAnimation
    ) {
      const phoneAnimation = showFromOpacity(phoneRef.current).pause();

      const animation = showBlock(borderRef.current, {
        onComplete: () => {
          phoneAnimation.play();
        },
      }).pause();

      onHTMLElementEnterOnWindow(borderRef.current, () => {
        animation.play();
      });
    }
  }, [isAnimation]);

  const handleMouseEnter = useCallback(() => {
    cursor?.enter();
  }, [cursor]);

  const handleMouseLeave = useCallback(() => {
    cursor?.leave();
  }, [cursor]);

  return (
    <div className={style.slide} style={{ height: height ?? 'auto' }}>
      <div className={style.descriptionWrapper}>
        <Typography
          className={style.title}
          variant='h3'
          ref={(ref) => ref && textAnimationRefs.current.push(ref)}
        >
          {title}
        </Typography>
        <Typography
          variant='body'
          ref={(ref) => ref && textAnimationRefs.current.push(ref)}
        >
          {description}
        </Typography>
        <div ref={linkRef}>
          <Link
            className={style.link}
            text='Project link'
            href={isProjectReady ? `/projects/${id}` : link}
            iconRightName='arrowRight'
            target={isProjectReady ? '_self' : '_blank'}
            rel='noreferrer'
            variant={isProjectReady ? 'internal' : 'external'}
          />
        </div>
      </div>
      <div
        className={style.imgWrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Link
          className={style.imageLink}
          href={isProjectReady ? `/projects/${id}` : link}
          target={isProjectReady ? '_self' : '_blank'}
          rel='noreferrer'
          variant={isProjectReady ? 'internal' : 'external'}
        >
          <>
            <div className={style.ratio} />
            <div
              className={style.frame}
              style={{ borderColor: frameColor }}
              ref={borderRef}
            />
            {image && (
              <div ref={phoneRef}>
                <GatsbyImage
                  alt='project_preview'
                  image={image}
                  className={style.image}
                  objectFit='contain'
                />
              </div>
            )}
          </>
        </Link>
        {/* <Tooltip position='mouse'>*/}
        {/*  <Typography variant='body'>Overview is coming soon</Typography>*/}
        {/* </Tooltip>*/}
      </div>
    </div>
  );
};

export default ProjectPreview;
