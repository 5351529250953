// components
import PageLayout from 'src/components/PageLayout';
// hooks
import usePortfolioMetaData from 'src/cms/data/portfolio/usePortfolioMetaData';
// sections
import Projects from './sections/Projects';
import Heading from './sections/Heading';
import { PageProps } from 'gatsby';
import { FC } from 'react';

const Portfolio: FC<PageProps> = ({ location }) => {
  // hooks
  const portfolioMetaData = usePortfolioMetaData();

  return (
    <PageLayout
      withStartProjectForm={false}
      withFooter={false}
      metaData={{ ...portfolioMetaData, href: location.href }}
    >
      <Heading />
      <Projects />
    </PageLayout>
  );
};

export default Portfolio;
