import { FC, useContext, useEffect, CSSProperties } from 'react';
// components
import Typography from 'src/components/Typography';
import Section from 'src/components/Section';
// animation
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
// context
import SitePreloaderContext from 'src/contexts/SitePreloaderContext';
import BannerContext from 'src/contexts/BannerContext';
// hooks
import useWindowSize from 'src/hooks/useWindowSize';

import * as style from './Heading.module.scss';

const defaultMarginTop = 77;

const Heading: FC = () => {
  // context
  const { isPageLoaded } = useContext(SitePreloaderContext);
  const { isTopBannerVisible, topBannerRef } = useContext(BannerContext);
  // hooks
  const { isTablet, isMobile } = useWindowSize();

  useEffect(() => {
    if (isPageLoaded) {
      const animation = appearanceOfText(`.${style.animatedText}`).pause();

      onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
        animation.play();
      });
    }
  }, [isPageLoaded]);

  const contentStyle: CSSProperties = {
    marginTop:
      (isTablet || isMobile) && isTopBannerVisible
        ? (topBannerRef.current?.clientHeight ?? 0) + defaultMarginTop
        : defaultMarginTop,
  };

  return (
    <Section rootClassName={style.root} withMargin={false}>
      <div className={style.content} style={contentStyle}>
        <div className={style.title}>
          <Typography className={style.animatedText} variant='h1'>
            Portfolio
          </Typography>
        </div>
        <div className={style.text}>
          <Typography className={style.animatedText} variant='body'>
            Some of our finest work.
          </Typography>
        </div>
      </div>
    </Section>
  );
};

export default Heading;
